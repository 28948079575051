.cell {
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;
}
thead tr th,
td {
  &.textCenter {
    text-align: center;
  }
  &.textLeft {
    text-align: left;
  }
  &.textRight {
    text-align: right;
  }
}
.textCenter {
  text-align: center;
}
