.cell {
  transition: background-color 0.1s, color 0.1s;
  &.active {
    color: white;
    background-color: var(--mantine-color-dark-6);
  }
  &.inactive {
    cursor: not-allowed;
    background-color: var(--mantine-color-gray-0);
    &:hover {
      color: inherit;
      background-color: inherit;
    }
  }
  cursor: pointer;
  &:hover {
    color: white;
    background-color: var(--mantine-color-dark-6);
  }
}
thead tr th {
  &.textCenter {
    text-align: center;
  }
}
.textCenter {
  text-align: center;
}
